<template>
  <MainPortfolio />
</template>


<script>
import MainPortfolio from "./components/MainPortfolio.vue";

export default {
  name: "Personal Portfolio",
  components: {
    MainPortfolio,
  },
};
</script>

<style>
#app {
  font-family: Lato, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
body {
  background-image: url(./assets/background.svg);
  background-attachment: fixed;
  background-size: auto 100%;
  background-position: center;
}
</style>
