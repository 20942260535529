<template>
  <h1 @mouseover="hoverText = true" @mouseleave="hoverText = false">
    {{ hoverText ? "Nano" : "Ignacio" }}
  </h1>
</template>

<script>
export default {
  name: "AltName",
  data() {
    return {
      hoverText: false,
    };
  },
};
</script>

<style scoped>
h1 {
  display: inline;
  transition: all 0.5s;
}
h1:hover {
  transition: all 0.5s;
  cursor: pointer;
  color: #0c050e;
  display: inline;
}
</style>