<template>
  <div class="technologies">
    <div class="prefix">{{ prefix }}</div>
    <div class="characteristic">{{ technologies[selectedTechnology] }}</div>
  </div>
</template>

<script>
export default {
  name: "TextCarousel",
  mounted() {
    setInterval(() => {
      if (this.selectedTechnology === this.technologies.length - 1) {
        this.selectedTechnology = 0;
      } else {
        this.selectedTechnology += 1;
      }
    }, this.time);
  },
  props: {
    technologies: Array,
    time: Number,
    prefix: String,
  },
  data() {
    return {
      selectedTechnology: 0,
    };
  },
};
</script>

<style scoped>
.prefix {
  font-weight: bold;
  font-size: 20px;
}
.characteristic {
  margin: 10px;
  letter-spacing: 2px;
  font-size: 24px;
  color: white;
}
.technologies {
  min-width: 300px;
  color: #231d25;
  display: inline-block;
  transition: all 1ms;
}
</style>